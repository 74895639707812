/// Menu
import MetisMenu from "metismenujs";
import React, {Component} from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import {Link} from "react-router-dom";

///
// import drump from "../../../images/card/drump.png";

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new MetisMenu(this.$el);
    }

    componentWillUnmount() {
        //  this.mm("dispose");
        // console.log(this.mm);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

class SideBar extends Component {
    /// Open menu
    componentDidMount() {
        // sidebar open/close
        var btn = document.querySelector(".nav-control");
        var aaa = document.querySelector("#main-wrapper");

        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle");
        }

        btn.addEventListener("click", toggleFunc);
    }

    state = {
        loveEmoji: false,
    };

    render() {
        /// Path
        let path = window.location.pathname;
        path = path.split("/");
        path = path[path.length - 1];

        console.log("sideBar.js path", path)

        /// Active menu
        let movies = ["movies"],
            series = ["series"],
            minisites = ["minisites"],
            catalogos = ["directores", "actores", "dealers", 'genres', 'blocks', 'awards'],
            metrics = ["royalties"],
            usuarios = ["users"],
            settings = ["configuración", "settings", "configuracion", "categories"],
            home = ["home","bloques", "banners"],
            dashboard = ["dashboard"],
            blog = ["blog"],
            blocks = ["blocks"]

        return (
            <div className="deznav">
                <PerfectScrollbar className="deznav-scroll">
                    <MM className="metismenu" id="menu">
                        {/* <li className={`${dashboard.includes(path) ? "mm-active" : ""}`}>
              <Link to="/dashboard" className="ai-icon" aria-expanded="false" >
                <i className="fa fa-area-chart"></i>
                <span className="nav-text">Informes</span>
              </Link>
            </li> */}
                        <li className={`${home.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                                <i className="flaticon-381-home"></i>
                                <span className="nav-text">Home</span>
                            </Link>
                            <ul aria-expanded="false">
                                <li>
                                    <Link
                                        className={`${path === "banners" ? "mm-active" : ""}`}
                                        to="/home/banners"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Banners
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "bloques" ? "mm-active" : ""}`}
                                        to="/home/bloques"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Bloques de contenido
                                    </Link>
                                </li>
                            </ul>
                        </li> 
                        <li className={`${movies.includes(path) ? "mm-active" : ""}`}>
                            <Link to="/movies" className="ai-icon" aria-expanded="false">
                                <i className="flaticon-381-video-player-1"></i>
                                <span className="nav-text">Películas / Cortos</span>
                            </Link>
                        </li>

                        <li className={`${series.includes(path) ? "mm-active" : ""}`}>
                            <Link to="/series" className="ai-icon" aria-expanded="false">
                                <i className="flaticon-381-television"></i>
                                <span className="nav-text">Series</span>
                            </Link>
                        </li>
                        <li className={`${minisites.includes(path) ? "mm-active" : ""}`}>
                            <Link to="/minisites" className="ai-icon" aria-expanded="false">
                                <i className="flaticon-381-networking"></i>
                                <span className="nav-text">Colaboraciones</span>
                            </Link>
                        </li>
                        <li className={`${catalogos.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                                <i className="flaticon-381-album-2"></i>
                                <span className="nav-text">Catálogos</span>
                            </Link>
                            <ul aria-expanded="false">
                                <li>
                                    <Link
                                        className={`${path === "directores" ? "mm-active" : ""}`}
                                        to="/catalogos/directores"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Directores
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "actores" ? "mm-active" : ""}`}
                                        to="/catalogos/actores"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Actores
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*  <Link*/}
                                {/*    className={`${path === "" ? "mm-active" : ""}`}*/}
                                {/*    to="/catalogos/personajes"*/}
                                {/*    onClick={() => this.props.onClick()}*/}
                                {/*  >*/}
                                {/*    Personalidades*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*  <Link*/}
                                {/*    className={`${path === "" ? "mm-active" : ""}`}*/}
                                {/*    to="/catalogos/premios"*/}
                                {/*    onClick={() => this.props.onClick()}*/}
                                {/*  >*/}
                                {/*    Premios*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                {/*<li>*/}
                                {/*  <Link*/}
                                {/*    className={`${path === "" ? "mm-active" : ""}`}*/}
                                {/*    to="/catalogos/nominations"*/}
                                {/*    onClick={() => this.props.onClick()}*/}
                                {/*  >*/}
                                {/*    Premios (Categorías)*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                                <li>
                                  <Link
                                    className={`${path === "" ? "mm-active" : ""}`}
                                    to="/catalogos/tags"
                                    onClick={() => this.props.onClick()}
                                  >
                                    Etiquetas
                                  </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "dealers" ? "mm-active" : ""}`}
                                        to="/catalogos/dealers"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Distribuidoras
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "genres" ? "mm-active" : ""}`}
                                        to="/catalogos/genres"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Géneros
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "blocks" ? "mm-active" : ""}`}
                                        to="/catalogos/blocks"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Bloques de contenido
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "awards" ? "mm-active" : ""}`}
                                        to="/catalogos/awards"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Premios
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "awards-categories" ? "mm-active" : ""}`}
                                        to="/catalogos/awards-categories"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Premios (Categorías)
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*  <Link className={`${path === "" ? "mm-active" : ""}`} to="/catalogos/rights" onClick={() => this.props.onClick()}>*/}
                                {/*    Derechos de Reproducción*/}
                                {/*  </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </li>

                        <li className={`${metrics.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                                <i className="fa fa-area-chart"></i>
                                <span className="nav-text">Métricas y reportes</span>
                            </Link>
                            <ul aria-expanded="false">
                                <li>
                                    <Link
                                        className={`${path === "royalties" ? "mm-active" : ""}`}
                                        to="/metrics/royalties"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Reporte de regalias
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "dashboard" ? "mm-active" : ""}`}
                                        to="/dashboard"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Estadísticas
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "dashboard" ? "mm-active" : ""}`}
                                        to="/metrics/downloads"
                                        onClick={() => this.props.onClick()}
                                    >
                                        Descargas
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={`${metrics.includes(path) ? "mm-active" : ""}`}>
                            <Link className="ai-icon" to="/promocodes" aria-expanded="false">
                                <i className="fa fa-solid fa-gift"></i>
                                <span className="nav-text">Códigos</span>
                            </Link>
                        </li>
                        <li className={`${usuarios.includes(path) ? "mm-active" : ""}`}>
                            <Link className={`${path === "" ? "mm-active" : ""}`} to="/users"
                                  onClick={() => this.props.onClick()}>
                                <i className="flaticon-381-user-9"></i>
                                <span className="nav-text">Usuarios</span>
                            </Link>
                        </li>
                        <li className={`${metrics.includes(path) ? "mm-active" : ""}`}>
                            <Link className="ai-icon" to="/ratings" aria-expanded="false">
                                <i className="fa fa-solid fa-star"></i>
                                <span className="nav-text">Comentarios</span>
                            </Link>
                        </li>
                        <li className={`${blog.includes(path) ? "mm-active" : ""}`}>
                            <Link to="/blog" className="ai-icon" aria-expanded="false">
                                <i className="flaticon-381-notebook"></i>
                                <span className="nav-text">Blog</span>
                            </Link>    
                        </li>

                        {/* <li className={`${blocks.includes(path) ? "mm-active" : ""}`}>
              <Link to="/content-blocks" className="ai-icon" aria-expanded="false" >
                <i className="flaticon-381-presentation"></i>
                <span className="nav-text">Bloques de contenido</span>
              </Link>
            </li> */}
                        {/* <li className={`${blocks.includes(path) ? "mm-active" : ""}`}>
              <Link to="/blocks-detail" className="ai-icon" aria-expanded="false" >
                <i className="flaticon-381-presentation"></i>
                <span className="nav-text">Blocks Detail</span>
              </Link>
            </li> */}
                        {/*<li className={`${settings.includes(path) ? "mm-active" : ""}`}>*/}
                        {/*  <Link to="#" className="has-arrow ai-icon" aria-expanded="false">*/}
                        {/*    <i className="flaticon-381-settings-2"></i>*/}
                        {/*    <span className="nav-text">Configuración</span>*/}
                        {/*  </Link>*/}
                        {/*  <ul aria-expanded="false">*/}
                        {/*    <li>*/}
                        {/*      <Link className={`${path === "" ? "mm-active" : ""}`} to="/settings/categories" onClick={() => this.props.onClick()}>*/}
                        {/*        Categorías*/}
                        {/*      </Link>*/}
                        {/*    </li>*/}
                        {/*    */}
                        {/*  </ul>*/}
                        {/*</li>*/}
                    </MM>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default SideBar;
